import React from "react"

import Layout from "../layouts/en"
import SEO from "../components/seo"
import StoryImage from "../components/story-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { FormattedMessage } from 'react-intl'

const NotFoundPage = (props) => (
  <Layout location={props.location}>
    <SEO title="404: Not found" />



<div className="h-screen w-screen bg-gray-100 flex items-center">

 <div className="container flex md:flex-row items-center justify-center px-5 text-gray-700 grid grid-cols-1 lg:grid-cols-2">
   		<div className="max-w-md flex-col pl-24 lg:place-self-end">
      		<div className="text-5xl font-dark font-bold">404</div>
            <p
              className="text-2xl md:text-3xl font-light leading-normal pr-10"
            >
               <FormattedMessage id="LABEL_PAGE_NOT_FOUND" />
            </p>
          <p className="mb-8">
          <FormattedMessage id="LABEL_SOMETHING_WRONG" />
          </p>
          <AniLink paintDrip color="gainsboro" to="/">
            <button className="mb-10 -mt-8 inline focus:outline-none border-2 border-blue-50 rounded-full font-bold text-blue-150 px-2 py-2 hover:bg-white hover:border-blue-100 hover:text-blue-150 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105">
            <svg xmlns="http://www.w3.org/2000/svg" className="inline sm:ml-1 w-6 stroke-current stroke-0 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110" viewBox="0 0 24 24" fill="currentColor" stroke-linecap="round" stroke-linejoin="round">
            <path d="M15.6,0.7c1.5,0.2,2.3,1.7,2.3,3.4c-0.2,1.7-1.3,3.2-2.8,3s-2.3-1.7-2.3-3.4S14.1,0.5,15.6,0.7z M8.8,0.7
	c1.5,0,2.5,1.5,2.5,3.2S10.1,7,8.8,7S6.3,5.5,6.3,3.9S7.5,0.7,8.8,0.7z M1.6,8.7c-0.2-1.5,0.6-3,1.9-3.2S6,6.3,6.3,8
	c0.2,1.5-0.6,3-1.9,3.2C3.1,11.3,1.8,10.2,1.6,8.7z M16,19.3c-1.1,0-2.5-1.1-4.2-1.1s-2.5,1.1-4,1.1c-1.7,0-3.4-1.5-3.4-3.6
	c0-1.5,1.7-3,2.8-3.8c0.2-0.2,1.7-2.3,2.1-2.5c0.8-0.6,1.3-1.1,2.5-1.1c1.5,0,2.5,0.6,3.2,1.5c0.4,0.4,1.1,1.7,1.3,1.9
	c0.8,0.8,2.8,2.1,3,4C19.6,18.1,17.7,19.3,16,19.3z M22.4,9.2c-0.2,1.5-1.7,2.8-3,2.3c-1.3-0.2-2.1-1.7-1.9-3.4
	c0.2-1.5,1.7-2.8,3-2.3C21.8,6,22.6,7.5,22.4,9.2z"/>
            </svg>
            <span className="sm:pl-2">
            <FormattedMessage id="LABEL_BACK_HOME" />
             </span>
            
            </button>
          </AniLink>
    </div>
      <div className="max-w-lg flex-grow h-auto flex-col pl-24 lg:pl-0">
        <div style={{ maxWidth: `500px` }}>
          <StoryImage alt="404" filename="404.png" />          
        </div>
    </div>
    
  </div>
</div>
  </Layout>
)

export default NotFoundPage

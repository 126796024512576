module.exports = {
    'selectLanguage': 'Русский',
    'see_the_story': 'Details...',
    'coming_soon':'Details\u00a0сoming\u00a0soon...',
    'LABEL_DIGITAL_PRODUCTS_DESIGNER':'DIGITAL PRODUCT DESIGNER',
    'LABEL_UX_AND_DESIGN':'UX research and UI design',
    'LABEL_HCI':'in human computer interactions',
    'LABEL_PORTFOLIO':'Portfolio',
    'LABEL_SKILLS':'Professional experience and\u00a0skills',
    'LABEL_SKILL1_TITLE':'Research and\u00a0problem analysis',
    'LABEL_SKILL1':', field studies, developing a\u00a0deep understanding of\u00a0user\u0027s needs, goals, aspects of\u00a0the interaction with the\u00a0product and\u00a0creating experiences that match those',
    'LABEL_SKILL2_TITLE':'Conducting interviews',
    'LABEL_SKILL2':' with stakeholders: business, subject matter experts, data scientists (in\u00a0case of\u00a0machine learning based project), potential\u00a0users',
    'LABEL_SKILL3_TITLE':'Storyboarding',
    'LABEL_SKILL3':', business processes modeling and\u00a0designing in\u00a0purpose to\u00a0get all stakeholders on\u00a0the\u00a0same page in\u00a0terms of\u00a0the\u00a0goal, еnd-to-end Customer Journey Mapping (CJM)',
    'LABEL_SKILL4_TITLE':'Solution prototyping',
    'LABEL_SKILL4':' from wireframes to\u00a0clickable prototype, experience architecture mapping (using Adobe\u00a0XD or\u00a0Figma)',
    'LABEL_SKILL5_TITLE':'User interface testing',
    'LABEL_SKILL5':' and\u00a0usability audit',
    'LABEL_SKILL6_TITLE':'Graphic mockups',
    'LABEL_SKILL6':' of\u00a0user interfaces, icons, pictograms… (Adobe\u00a0Illustrator\u00a0&\u00a0Photoshop)',
    'LABEL_SKILL7_TITLE':'Leading SCRUM',
    'LABEL_SKILL7':' based development processes using Trello, Jira',
    'LABEL_SKILL8_TITLE':'Assisting to\u00a0development team',
    'LABEL_SKILL8':' to\u00a0transform design into\u00a0product\u00a0UI, reviewing and\u00a0auditing block layouting in\u00a0HTML, CSS',
    'LABEL_SKILL9_TITLE':'Mentoring',
    'LABEL_SKILL9':' junior designers (coaching, feedback, support in\u00a0complex tasks)',
    'LABEL_JOKE':'my usual duties...',
    'LABEL_SOMETHING_WRONG':'Something went wrong...',
    'LABEL_PAGE_NOT_FOUND':'Oops... Page not found',
    'LABEL_BACK_HOME':' Back Home',
    'LABEL_BACK':' Back',
    'LABEL_GO_UP':' Up',
    'LABEL_COPYRIGHT':' Maria Budarevskaya',
  };